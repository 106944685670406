<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs">
            <!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
          </ul>

          <div class="tab-content">
            <div class="tab-pane active">
              <div class="panel-body">
                <fieldset>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">#</label>
                    <div class="col-sm-10">
                      <input
                        v-model="data['uid']"
                        class="form-control"
                        type="text"
                        name="uid"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Receipt ID</label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          v-model="data['receipt_id']"
                          class="form-control"
                          type="text"
                          name="receipt_id"
                          :readonly="true"
                        />
                        <span class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="onClickLinkReceipt"
                          >
                            {{ "Link" }}
                          </button>
                        </span>
                      </div>
                      <span class="form-text">{{ "" }}</span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">User Name</label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          v-model="data['user_name']"
                          class="form-control"
                          type="text"
                          name="user_name"
                          :readonly="true"
                        />
                        <span class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="onClickLinkUser"
                          >
                            {{ "Link" }}
                          </button>
                        </span>
                      </div>
                      <span class="form-text">{{ "" }}</span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Store Name</label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          v-model="data['store_name']"
                          class="form-control"
                          type="text"
                          name="store_name"
                          readonly="readonly"
                        />
                        <span class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="onClickLinkStore"
                          >
                            {{ "Link" }}
                          </button>
                        </span>
                      </div>
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Ticket Code</label>
                    <div class="col-sm-10">
                      <input
                        v-model="data['ticket_code']"
                        class="form-control"
                        type="text"
                        name="ticket_code"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Ticket Start</label>
                    <div class="col-sm-10">
                      <datetime
                        v-model="data['ticket_date_start']"
                        class="form-control"
                        type="datetime"
                        zone="Asia/Seoul"
                        name="ticket_date_start"
                        :week-start="0"
                        :placeholder="data['ticket_date_start']"
                        :disabled="true"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Ticket End</label>
                    <div class="col-sm-10">
                      <datetime
                        v-model="data['ticket_date_end']"
                        class="form-control"
                        type="datetime"
                        zone="Asia/Seoul"
                        name="ticket_date_end"
                        :week-start="0"
                        :placeholder="data['ticket_date_end']"
                        :disabled="true"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Ticket Status</label>
                    <div class="col-sm-10">
                      <input
                        :value="data.status"
                        class="form-control"
                        type="text"
                        name="status"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Ticket Type</label>
                    <div class="col-sm-10">
                      <input
                        :value="data.ticket_type"
                        class="form-control"
                        type="text"
                        name="ticket_type"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Issue Type</label>
                    <div class="col-sm-10">
                      <input
                        :value="data.issue_type"
                        class="form-control"
                        type="text"
                        name="issue_type"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Ticket Quantity</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="data.ticket_quantity"
                        class="form-control"
                        type="text"
                        name="ticket_quantity"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Ticket Over Quantity</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="data.ticket_over_quantity"
                        class="form-control"
                        type="text"
                        name="ticket_over_quantity"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Memo</label>
                    <div class="col-sm-10">
                      <input
                        :value="data.memo"
                        class="form-control"
                        type="text"
                        name="memo"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Datetime Issued</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="
                          data.datetime_issued
                            ? $moment(data.datetime_issued).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''
                        "
                        class="form-control"
                        type="text"
                        name="datetime_issued"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Datetime Checkin</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="
                          data.datetime_check_in
                            ? $moment(data.datetime_check_in).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''
                        "
                        class="form-control"
                        type="text"
                        name="datetime_check_in"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Datetime Checkout</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="
                          data.datetime_check_out
                            ? $moment(data.datetime_check_out).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''
                        "
                        class="form-control"
                        type="text"
                        name="datetime_check_out"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Datetime Expired</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="
                          data.dateime_expired
                            ? $moment(data.datetime_expired).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''
                        "
                        class="form-control"
                        type="text"
                        name="datetime_expired"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label"
                      >Datetime Cancelled</label
                    >
                    <div class="col-sm-10">
                      <input
                        :value="
                          data.datetime_cancelled
                            ? $moment(data.datetime_cancelled).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''
                        "
                        class="form-control"
                        type="text"
                        name="datetime_cancelled"
                        readonly="readonly"
                      />
                      <span class="form-text"></span>
                    </div>
                  </div>

                  <div class="hr-line-dashed"></div>
                </fieldset>

                <div class="form-group row">
                  <div class="actions">
                    <div class="left">
                      <button
                        class="btn btn-white btn-sm"
                        @click="onClickCancel"
                      >
                        Cancel
                      </button>
                    </div>

                    <div class="right">
                      <button
                        v-if="
                          data['status'] === 'ISSUED' ||
                            data['status'] === 'EXPIRED'
                        "
                        class="btn btn-danger btn-sm"
                        :disabled="sending"
                        @click="onClickCheckIn"
                      >
                        체크인
                      </button>
                      <button
                        v-if="data['status'] === 'CHECK_IN'"
                        class="btn btn-danger btn-sm"
                        :disabled="sending"
                        @click="onClickCheckOut"
                      >
                        체크아웃
                      </button>
                      <button
                        v-if="data['status'] !== 'CANCELLED'"
                        class="btn btn-info btn-sm"
                        :disabled="sending"
                        @click="onClickReserveCancel"
                      >
                        예약취소
                      </button>
                      <button
                        v-if="
                          data['status'] === 'CHECK_IN' &&
                            data['ticket_type'] === 'PRODUCT'
                        "
                        class="btn btn-info btn-sm"
                        :disabled="sending"
                        @click="onClickReverseStatus"
                      >
                        상태복원
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import Editor from "@/components/utils/Editor";
import ExtraEditor from "@/components/utils/ExtraEditor";
import ResourceEditor from "@/components/utils/ResourceEditor";
import moment from "moment-timezone";

export default {
  data() {
    return {
      mode: "unset",
      model: {},
      data: {},
      sending: false,
      items: [],
    };
  },
  mounted() {
    this.$data.mode = this.$route.meta.mode;
    this.$data.model = this.$route.meta.model;

    this.loadData();
  },
  watch: {
    data(value, oldValue) {},
  },
  computed: {},
  methods: {
    loadData() {
      this.$store
        .dispatch("products/get", this.$route.params)
        .then((data) => {
          // data.ticket_date_start = data.ticket_date_start.replace(' ','T') + ':00'
          // data.ticket_date_end = data.ticket_date_end.replace(' ','T') + ':00'
          this.$data.data = data;
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onClickLinkReceipt(e) {
      this.$router.push({
        name: "ViewReceipt",
        params: { uid: this.data.receipt_id },
      });
    },

    onClickLinkUser(e) {
      this.$router.push({
        name: "EditUser",
        params: { uid: this.data.user_id },
      });
    },

    onClickLinkStore(e) {
      this.$router.push({
        name: "EditStore",
        params: { uid: this.data.store_id },
      });
    },

    onClickCancel(e) {
      this.$router.push({
        name: this.model.views.list.name,
        params: {},
      });
    },

    onClickCheckIn() {
      this.$dialog
        .confirm(`${this.data.uid} 티켓을 체크인 처리할까요?`, {
          okText: "체크인 시각 설정",
          cancelText: "Cancel",
        })
        .then((dialog) => {
          this.setCheckInTime();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    setCheckInTime() {
      let options = {
        html: true,
      };

      let now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      let message = `<label> 체크인 시각 <input id="checkinTime" type="datetime-local" value=${now}  style="margin-left: 1rem"></label>`;

      this.$dialog
        .confirm(message, options)
        .then((dialog) => {
          let value = document.getElementById("checkinTime").value;
          this.onCheckIn(value);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onCheckIn(value) {
      let params = {
        uid: this.$route.params.uid,
        checkin_time: this.$moment(value).format("YYYY-MM-DD HH:mm:ss"),
      };

      this.$data.sending = true;
      this.$store
        .dispatch("products/checkin", params)
        .then((data) => {
          this.$toast.success("체크인 되었습니다.");
          this.loadData();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        })
        .finally(() => {
          this.$data.sending = false;
        });
    },

    onClickCheckOut() {
      this.$dialog
        .confirm(`${this.data.uid} 티켓을 체크아웃 처리할까요?`, {
          okText: "체크아웃 시각 설정",
          cancelText: "Cancel",
        })
        .then((dialog) => {
          this.setCheckOutTime();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    setCheckOutTime() {
      let options = {
        html: true,
      };

      let now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      let message = `<label> 체크아웃 시각 <input id="checkoutTime" type="datetime-local" value=${now}  style="margin-left: 1rem"></label>`;

      this.$dialog
        .confirm(message, options)
        .then((dialog) => {
          let value = document.getElementById("checkoutTime").value;
          this.onCheckOut(value);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onCheckOut(value) {
      let params = {
        uid: this.$route.params.uid,
        checkout_time: this.$moment(value).format("YYYY-MM-DD HH:mm:ss"),
      };

      this.$data.sending = true;

      if (this.data.issue_type == "PAID") {
        this.$store
          .dispatch("products/checkout", params)
          .then((data) => {
            this.$toast.success("체크아웃 되었습니다.");
            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          })
          .finally(() => {
            this.$data.sending = false;
          });
      } else if (
        this.data.issue_type == "RESERVE" ||
        this.data.issue_type == 'DEPOSIT_ADVANCE' ||
        this.data.issue_type == "PAY_LATER"
      ) {
        this.$store
          .dispatch("products/checkoutAfter", params)
          .then((data) => {
            this.$toast.success("체크아웃 되었습니다.");
            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          })
          .finally(() => {
            this.$data.sending = false;
          });
      }
    },

    onClickReserveCancel() {
      this.$dialog
        .confirm(`${this.data.uid} 티켓의 예약을 취소할까요?`, {
          okText: "취소사유 입력",
          cancelText: "Cancel",
        })
        .then((dialog) => {
          this.setCancelReason();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    setCancelReason() {
      let options = {
        html: true,
      };

      let value = "";

      let message = `<label> 취소 사유 <input type="text" placeholder="취소사유를 입력해주세요" id="cancel-reason" value=${value}></label>`;

      this.$dialog
        .confirm(message, options)
        .then((dialog) => {
          let value = document.getElementById("cancel-reason").value;
          this.onCancel(value);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onClickReverseStatus() {
      this.$dialog
        .confirm(
          `${this.data.uid} 티켓의 상태를 체크인에서 예약 완료로 복원하시겠습니까?`,
          {
            okText: "Confirm",
            cancelText: "Cancel",
          }
        )
        .then((dialog) => {
          this.onReverseStatus();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onCancel( value ) {
      let params = {
        uid: this.data.receipt_id,
        cancel_reason_detail: value || ""
      };

      this.$data.sending = true;
      this.$store
        .dispatch("receipt/cancel", params)
        .then((data) => {
          this.$toast.success("취소되었습니다.");
          this.loadData();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        })
        .finally(() => {
          this.$data.sending = false;
        });
    },

    onReverseStatus() {
      let params = {
        uid: this.data.uid,
        to: "ISSUED",
      };

      this.$data.sending = true;
      this.$store
        .dispatch("products/reverseStatus", params)
        .then((data) => {
          this.$toast.success("예약완료로 복원하였습니다.");
          this.loadData();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        })
        .finally(() => {
          this.$data.sending = false;
        });
    },
  },
  components: {
    Editor,
    ExtraEditor,
    ResourceEditor,
  },
};
</script>
